import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as appActions from './app.actions';
import * as appReducer from './app.reducer';
import * as appSelector from './app.selector';
import { Action, Store, select } from '@ngrx/store';
import { Observable, withLatestFrom, switchMap, map, catchError, of, filter, EMPTY } from 'rxjs';
import { RequestData } from '../common/models/antart-api-request.model';
import { LoadDataService } from '../core/services/load-data.service';
import { AntarConstants } from '../common/antar.constants';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Response, ResponseData, ResponseDeviceData, Selectivity } from '../common/models/antar-api-response.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppEffects {
	constructor ( private actions$: Actions,
		private store: Store<appReducer.State>,
		private loadDataService: LoadDataService,
		private router: Router ) { }

	LoadGraphData$: Observable<Action> = createEffect( () => {
		return this.actions$.pipe(
			ofType( appActions.LoadGraphData ),
			withLatestFrom(
				this.store.pipe(
					select( appSelector.getInputRequestData )
				)
			),
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			switchMap( ( [ action, inputRequestData ]: [{ type: '[Antar] Load Graph Data for Input Request' }, RequestData] ) => {
				return this.loadDataService.fetchGraphPoints( inputRequestData );
			} ),
			catchError( ( error: HttpErrorResponse ) => {
				const errorMessage: string[] = [];
				const errorResponse = error?.error;
				if ( errorResponse.length > 0 ) {
					errorResponse.forEach( ( data: { responseMessage: string } ) => {
						errorMessage.push( data.responseMessage );
					} );
				}
				if ( errorMessage.length > 0 ) {
					this.store.dispatch(
						appActions.SetErrorMessage( {
							notification: [ { error: errorMessage } ]
						} )
					);
				}
				return of( undefined );
			} ),
			map( ( responseData: ResponseData | undefined ) => {
				this.store.dispatch( appActions.UpdateLoader( { loadingtype: AntarConstants.LOADER_TYPE['MAIN_LOADER'], loader: false, message: '' } ) );
					if ( responseData ) {
						return appActions.LoadResponseDataSuccess( { responseData } );
					}
					this.router.navigate( [ '/access-denied' ] );
					return appActions.LoadResponseDataFailure();
			} )
		);
	} );
	SelectivityResultData$: Observable<Action> = createEffect( () => {
		return this.actions$.pipe(
			ofType( appActions.LoadSelectivityResultData ),
			withLatestFrom(
				this.store.pipe(
					select( appSelector.getDeviceData )
				),
				this.store.pipe( select( appSelector.getSelectivityResultData ) )
			),
			//eslint-disable-next-line @typescript-eslint/no-unused-vars
			switchMap( ( [ action, deviceData, selectivity ]: [{ type: '[Antar] Load Selectivity Result Data' }, ResponseDeviceData[], {selectivity:Selectivity|null | undefined} ] ) => {
				if ( deviceData.length > 1 ) {
				return ( selectivity.selectivity && selectivity.selectivity !== null && environment.launchSystem !== AntarConstants.LAUNCHSYSTEM.ANTARES ) ? of( selectivity.selectivity ) :
				this.loadDataService.getSelectivityResult( deviceData );
				} else return EMPTY;
			} ),
			filter( ( selectivity ): selectivity is Selectivity => selectivity !== null ),
			map( ( selectivity: Selectivity ) => {
				this.store.dispatch( appActions.UpdateLoader( { loadingtype: AntarConstants.LOADER_TYPE['INFORMATION_LOADER'], loader: false, message: '' } ) );
				return appActions.LoadSelectivityResultDataSuccess( { selectivity } );
			} )
		);
	} );
	LoadSelectivityResultDataByTable$: Observable<Action> = createEffect( () => {
		return	this.actions$.pipe(
			ofType( appActions.LoadSelectivityResultDataByTable ),
			withLatestFrom(
				this.store.pipe(
					select( appSelector.getInputRequestData )
				)
			),
			//eslint-disable-next-line @typescript-eslint/no-unused-vars
			switchMap( ( [ action, inputRequestData ]: [{ type: '[Antar] Load Selectivity Result Data By Table' }, RequestData] ) => {
				if ( inputRequestData.data.length > 1 )
				return this.loadDataService.getSelectivityResultByTable( inputRequestData );
				else return EMPTY;
			} ),
			filter( ( selectivity ): selectivity is Response => selectivity !== null ),
			map( ( selectivity: Response ) => {
				this.store.dispatch( appActions.UpdateLoader( { loadingtype: AntarConstants.LOADER_TYPE['INFORMATION_LOADER'], loader: false, message: '' } ) );
				return appActions.LoadSelectivityResultDataByTableSuccess( { selectivity } );
			} )
		);
	} );
}
